import React, { useEffect, useState } from "react"
import waveImagingLogo from "../images/logo-Waveimaging.png"
import waveImagingMap from "../images/waveimaging-map.jpg"
//import locations from "../data/locations"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ListItem from "../components/locationItem"

const IndexPage = () => {
  let [locations, setlocations] = useState([])

  useEffect(()=>{
    fetch(`http://52.14.233.166:3000/centers-json`).
    then(res => res.json()).
    then(res => {
      setlocations(res)
    })
    .catch(err => console.log(err))
  })
  return (
    <Layout>
      <SEO title="WaveImaging" />
      <section id="splash" className="container">
        <div className={`centered`}>
          <img
            src={waveImagingLogo}
            alt="Wave Imaging Logo"
            style={{ width: `100%`, maxWidth: `600px` }}
          />
          <p>
            A Premier Network of Outpatient Imaging Centers
            <br />
            in the Orange and Los Angeles Counties
          </p>
        </div>
        <div className="map-area">
          <img className="map" src={waveImagingMap} alt="Wave Imaging" />
        </div>
        <div className="locations-info columns">
          <div className="column map-region-oc">
            <div>
              <ul>
                {locations
                  .slice(0, locations.length / 2 - 1)
                  .map((item, idx) => {
                    return <ListItem item={item} key={idx} />
                  })}
              </ul>
            </div>
          </div>
          <div className="column map-region-lb">
            <div>
              <ul>
                {locations
                  .slice(locations.length / 2, locations.length)
                  .map((item, idx) => {
                    return <ListItem item={item} key={idx} />
                  })}
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-6 has-text-centered ">
          <a className="button is-success is-large" href="tel:+19493875000">
            <i className="fas fa-phone-square-alt"></i>
            &nbsp;&nbsp;949-387-5000
          </a>
        </div>
        <form className="mt-6 has-text-centered">
          <div className="container is-desktop">
            <h2 className="is-size-4 mb-4">Request Appointment</h2>
            <div className="field">
              <div className="control">
                <input className="input is-success" type="text" placeholder="Enter Name" />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <input
                  className="input is-danger"
                  type="email"
                  placeholder="Enter Email"
                />
              </div>
            </div>
            <div className="field ">
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Enter a message"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="mt-4 field is-grouped">
            <div class="control">
              <button class="button is-link">Submit</button>
            </div>
            <div class="control">
              <button class="button is-link is-light">Cancel</button>
            </div>
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default IndexPage
